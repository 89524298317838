// src/context/UserContext.js
import React, { useEffect, createContext, useContext, useState } from 'react';
import { useMsal } from '@azure/msal-react';

const UserContext = createContext();

export const UserProvider = ({ children, initialAccessLevel }) => {
  const { instance } = useMsal();
  const [userName, setUserName] = useState(undefined);
  const [accessLevel, setAccessLevel] = useState(initialAccessLevel);

  // Get the account information
  const accounts = instance.getAllAccounts();
  const account = accounts && accounts.length > 0 ? accounts[0] : null;

  useEffect(() => {
    if (account) {
      setUserName(account.name || account.username);
    }
  }, [account]);

  return (
    <UserContext.Provider value={{ userName, accessLevel}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
