import React, { useState, useEffect } from 'react';
import '../styles/ProductInputs.css';
import { useUser } from '../context/UserContext';

const ProductInputs = ({ extra_inputs, updateValue }) => {
  const { userName, accessLevel } = useUser();
  const [inputs, setInputs] = useState(extra_inputs || []); // Initialize local state

  // Sync local state with extra_inputs when it changes
  useEffect(() => {
    setInputs(extra_inputs || []);
  }, [extra_inputs]);

  const productName = inputs?.productName || '';

  const processChange = (e, db_update = false) => {
    const { name, type, checked, value } = e.target;
    let inputValue;

    if (type === 'checkbox' && name === 'Scrub') {
      // Convert boolean to 1.0 or 0.0 only for 'Scrub'
      inputValue = checked ? 1.0 : 0.0;
    } else {
      inputValue = parseFloat(value);
    }

    // Update the local state before calling updateValue
    const updatedInputs = {
      ...inputs,
      parameters: inputs.parameters.map((item) =>
        item.name === name ? { ...item, value: inputValue } : item
      )
    };
    setInputs(updatedInputs); // Update local state to trigger UI re-render

    // Call the external update function
    updateValue(productName, name, inputValue, userName, db_update);
  };

  const handleInputChange = (e) => {
    processChange(e);
  };

  const handleClickChange = (e) => {
    processChange(e, true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      processChange(e, true);
    }
  };

  return (
    <div className="input-field horizontal">
      {inputs &&
        inputs.parameters &&
        inputs.parameters.map((item, idx) => {
          // Only convert floats back to booleans for display purposes if the item is 'Scrub'
          const value =
            item.name === 'Scrub' &&
              typeof item.value === 'number' &&
              (item.value === 1.0 || item.value === 0.0)
              ? Boolean(item.value)
              : item.value;

          return (
            <label key={idx} className="input-label">
              <span>{item.name}</span>
              {item.name === 'Scrub' && typeof value === 'boolean' ? (
                <input
                  type="checkbox"
                  name={item.name}
                  checked={value}
                  onChange={handleClickChange}
                  readOnly={accessLevel === 'reader'}
                />
              ) : (
                <input
                  type="text"
                  name={item.name}
                  value={value ?? ''} // Ensure 0 is displayed correctly
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  readOnly={accessLevel === 'reader'}
                />
              )}
            </label>
          );
        })}
    </div>
  );
};

// Custom comparison function for props
function arePropsEqual(prevProps, nextProps) {
  if (prevProps.extra_inputs.length !== nextProps.extra_inputs.length) {
    return false;
  }
  const numItems = prevProps.extra_inputs.length;
  for (let i = 0; i < numItems; i++) {
    const prevItem = prevProps.extra_inputs[i];
    const nextItem = nextProps.extra_inputs[i];
    if (prevItem !== nextItem) {
      return false;
    }
  }
  return true;
}

export default React.memo(ProductInputs, arePropsEqual);
