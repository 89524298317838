import React, { useEffect, useState } from 'react';
import '../styles/ProductTable.css';
import { formatNumber } from '../helpers/formatNumber';

const DateColumn = ({ dates, brentPrices, quarters, years, pnl }) => {
  return (
    <div className='index-product-container content'>
      <h2 className="h2-product h2-fake-header">_</h2>
      <table className="excel-table index-column-table">
        <thead>
          <tr>
            <th>Date</th>
            {brentPrices && <th>Brent</th>}
          </tr>
        </thead>
        <tbody>
          {dates.map((date, index) => (
            <tr key={index}>
              <td>{date}</td>
              {brentPrices && <td>{formatNumber(brentPrices[index], 2)}</td>}
            </tr>
          ))}
        </tbody>
      </table>
      <table className="excel-table index-column-table">
        <thead>
          <tr>
            <th>Quarter</th>
          </tr>
        </thead>
        <tbody>
          {quarters.map((quarter, index) => (
            <tr key={index}>
              <td>{quarter}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className="excel-table index-column-table">
        <thead>
          <tr>
            <th>Year</th>
          </tr>
        </thead>
        <tbody>
          {years.map((year, index) => (
            <tr key={index}>
              <td>{year}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DateColumn;
