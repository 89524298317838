// src/pages/NotAuthorized.js

import React from 'react';
import styles from '../../styles/NotAuthorized.module.css'; // Import the CSS module for styling
import { useMsal } from '@azure/msal-react';

const NotAuthorized = () => {
  const { instance } = useMsal();

  const handleSignOut = async (e) => {
    e.preventDefault()
    try {
      await instance.logoutRedirect()
    } catch (err) {
      console.error(err);
    };
  };

  return (
    <div className={styles.notAuthorizedBody}>
      <div className={styles.notAuthorizedContainer}>
        <h1 className={styles.notAuthorizedTitle}>401</h1>
        <p className={styles.notAuthorizedMessage}>This user is not authorized to view this page.</p>
        <button onClick={handleSignOut}>
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default NotAuthorized;
