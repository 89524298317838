import axios from 'axios';
import { backendURL } from '../config/config';

// Create an Axios instance
const api = axios.create({
    baseURL: backendURL
});

// Add a request interceptor to include the custom header in every request
api.interceptors.request.use(
    (config) => {
        const customHeaderValue = process.env.REACT_APP_MTM_SECRET_STRING
        config.headers['X-Custom-Header'] = customHeaderValue;
        return config;
    },
    (error) => {
        // Handle the error
        return Promise.reject(error);
    }
);

export default api;
