import { updateMTMEntry, updateMTMExtraInputValue } from '../services/apiService';

export const updateEntry = async (
  product_id,
  column_name,
  entry_id,
  new_value,
  last_update_user,
  last_update,
  index
) => {
  const is_percent_column = column_name.includes('%')
  const body = {
    product_id: parseInt(product_id),
    column_name,
    entry_id: entry_id ? parseInt(entry_id) : null,
    new_value: is_percent_column ? parseFloat(new_value) / 100 : parseFloat(new_value),
    last_update_user,
    last_update,
    index
  }
  await updateMTMEntry(body)
};

export const updateExtraInput = async (
  product_name,
  new_value,
  extra_input_name,
  last_update_user,
  last_update
) => {
  const body = {
    product_name,
    extra_input_name,
    new_value: parseFloat(new_value),
    last_update_user,
    last_update
  }
  await updateMTMExtraInputValue(body)
};