import React from 'react';
import '../../styles/Tooltip.css'; // Make sure to create this CSS file

const Tooltip = ({ content, children }) => (
  <div className="tooltip-container">
    {children}
    <span className="tooltip-text">{content}</span>
  </div>
);

export default Tooltip;
