import React, { useState } from "react";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import styles from '../../styles/LogInPage.module.css'; // Use CSS module
import { useNavigate } from 'react-router-dom';

console.log("Test environment", process.env)
const LoginPage = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    setLoading(true);
    try {
      await instance.loginRedirect(loginRequest);
      navigate("/lpg");
    } catch (e) {
      setError("Login failed, please try again.");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.loginBody}>
      <div className={styles.loginContainer}>
        <h1 className={styles.loginTitle}>MTM</h1>
        <button className={styles.loginButton} onClick={handleLogin} disabled={loading}>
          {loading ? "Signing In..." : "Sign In"}
        </button>
        {error && <p className={styles.errorText}>{error}</p>}
      </div>
    </div>
  );
};

export default LoginPage;