// src/App.js
import React, { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import LPGPage from './components/Pages/LPG';
import PositionsPage from './components/Pages/PositionsPage';
import PNLPage from './components/Pages/P&LPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import LoginPage from './components/Pages/LogInPage';
import PageNotFound from './components/Pages/PageNotFound';
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import './App.css';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import NotAuthorized from './components/Pages/NotAuthorized';
import LoadingAnimation from './components/LoadingAnimation';


const App = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState(true)
  const [accessLevel, setAccessLevel] = useState("not_authorized")

  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, []);

  useEffect(() => {
    const fetchAccessLevel = async () => {
      setIsLoading(true)
      try {
        if (!isAuthenticated) {
          setIsLoading(false);
          return;
        }

        const accounts = instance.getAllAccounts();
        const account = accounts && accounts.length > 0 ? accounts[0] : null;

        instance.setActiveAccount(account);

        const tokenResponse = await instance.acquireTokenSilent({
          account: account,
          scopes: ["User.Read"],
        });

        const token = tokenResponse.idToken;
        const payload = JSON.parse(atob(token.split(".")[1]));

        const roles = payload.roles || [];

        // Determine access level based on roles
        let accessLevel = "none";
        if (roles.includes("writer")) {
          accessLevel = "writer";
        } else if (roles.includes("reader")) {
          accessLevel = "reader";
        } else {
          accessLevel = "not_authorized";
        }

        setAccessLevel(accessLevel)
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchAccessLevel()
  }, [isAuthenticated])

  if (isLoading) {
    return <LoadingAnimation />
  }

  if (!isAuthenticated) {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </Router>
    )
  } else if (accessLevel === "not_authorized") {
    return <NotAuthorized />
  }

  // Determine if the current route is the login page
  const isIndependentPage = currentPath === '/login' || currentPath === "/not_authorized"

  return (
    <UserProvider initialAccessLevel={accessLevel}>
      <Router>
        <div className={isIndependentPage ? 'login-container' : 'app-container'}>
          {isAuthenticated && !isIndependentPage && <Navbar />}
          <div className={isIndependentPage ? 'login-content' : 'main-content'}>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/lpg" element={<ProtectedRoute> <LPGPage /> </ProtectedRoute>} />
              <Route path="/positions" element={<ProtectedRoute> <PositionsPage /> </ProtectedRoute>} />
              <Route path="/pnl" element={<ProtectedRoute> <PNLPage /> </ProtectedRoute>} />
              <Route path="/" element={<ProtectedRoute><LPGPage /></ProtectedRoute>} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
      </Router>
    </UserProvider>
  );
};

export default App;
