import React, { useRef, useState } from 'react';
import ColumnInput from '../ColumnInput';
import { formatNumber } from '../../helpers/formatNumber';
import { getBackgroundColorClass } from '../../helpers/productHelper';
import Tooltip from './Tooltip';
import "../../styles/shared.css";
import { updateInBulk } from '../../services/apiService';
import { useUser } from '../../context/UserContext';
import { formatDate } from '../../helpers/timeHelper';

const shouldApplyFormatting = (productName, columnName, value) => {
  if (productName.includes("Netback") && ["Curve", "53%"].includes(columnName)) {
    const numericValue = parseFloat(value);
    if (numericValue > 5) {
      return { extraContent: '', className: 'green-background arrow-icon' };
    } else if (numericValue >= 4 && numericValue <= 5) {
      return { extraContent: '', className: 'yellow-background' };
    } else if (numericValue < 4) {
      return { extraContent: '', className: 'red-background cross-icon' };
    }
  }
  return { extraContent: null, className: '' };
};

const ProductMainTable = ({
  product,
  columns,
  dates,
  updateValue,
  handleKeyDown,
  selectedProductName,
  selectedColumn,
  setSelectedColumn,
}) => {
  const inputRefs = useRef([]);
  const { userName } = useUser();
  const [selectedRow, setSelectedRow] = useState(null); // Track selected row

  // Focus navigation between inputs
  const focusNextInput = (rowIndex, colIndex, rowDirection = 0, colDirection = 0) => {
    const nextRow = rowIndex + rowDirection;
    const nextCol = colIndex + colDirection;

    if (
      nextRow >= 0 &&
      nextRow < dates.length &&
      nextCol >= 0 &&
      nextCol < columns.length &&
      inputRefs.current[nextRow] &&
      inputRefs.current[nextRow][nextCol]
    ) {
      inputRefs.current[nextRow][nextCol].focus();
    }
  };

  // Handle selecting a column
  const handleColumnSelect = (colIndex, rowIndex) => {
    setSelectedColumn(colIndex);
    setSelectedRow(rowIndex); // Track the row the user clicked on
  };

  // Handle pasting clipboard data into the selected column starting from the clicked row
  const handlePaste = async (event) => {
    event.preventDefault(); // Prevent default paste behavior
    const column = columns[selectedColumn];
    if (selectedProductName !== product.name || selectedColumn === null || selectedRow === null || !column.quotations) return; // Ensure a column is selected and a row is selected

    const clipboardData = event.clipboardData.getData('text'); // Get clipboard data as text
    const pastedValues = clipboardData
      .split(/\r?\n/)
      // One of the butane columns may have a % sign
      .map((value) => value.trim().replace(/%/g, ""))
      .filter((value) => value !== "");


    // Update the selected column starting from the selected row with the pasted values
    updateValuesFromPaste(pastedValues, column, userName, product, selectedRow);
  };

  return (
    <div onPaste={handlePaste}> {/* Capture paste events */}
      <table className="excel-table">
        <thead>
          <tr>
            {columns.map((column, colIndex) => (
              <th
                key={colIndex}
                className={
                  selectedProductName === product.name && selectedColumn === colIndex
                    ? 'selected-column'
                    : ''
                }
              >
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dates.map((_, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => {
                const entry = column.quotations ? column.quotations[rowIndex] : undefined;
                const isTsColumn = column.quotations;
                const value = entry ? entry.value : column.value[rowIndex]?.value || column.value[rowIndex] || "";
                const last_update = entry ? entry.last_update : column.value[rowIndex]?.last_update;
                const last_update_user = entry ? entry.last_update_user : column.value[rowIndex]?.last_update_user;
                const isEditableEntry = !isTsColumn && column.value[rowIndex]?.index === rowIndex;

                if (!inputRefs.current[rowIndex]) {
                  inputRefs.current[rowIndex] = [];
                }

                if (isTsColumn || isEditableEntry) {
                  const previous_value = entry ? entry.previous_value : column.value[rowIndex]?.previous_value;
                  const change_direction = value > previous_value ? 1 : value === previous_value ? 0 : -1;
                  const lastUpdateClass = getBackgroundColorClass(last_update, change_direction);
                  const class_name = isTsColumn ? `column-input-container-${lastUpdateClass}` : `column-input-extra-cell-${lastUpdateClass}`;
                  const numDecimals = isTsColumn ? 2 : 3;
                  const entry_id = entry?.id;
                  const index = rowIndex;

                  return (
                    <td key={colIndex} className={class_name} onClick={() => {
                      handleColumnSelect(colIndex, rowIndex)
                      setSelectedRow(rowIndex)
                    }
                    }>
                      <Tooltip content={`Last updated by ${last_update_user || ''} on ${last_update || ''}`}>
                        <ColumnInput
                          value={value}
                          onChange={(e) => updateValue(product.name, `${column.name}-${entry_id}-${index}`, e.target.value, false)}
                          onKeyDown={handleKeyDown}
                          product_name={product.name}
                          column_name={column.name}
                          entry_id={entry_id}
                          index={index}
                          onFocusNext={(rowDir, colDir) => focusNextInput(rowIndex, colIndex, rowDir, colDir)} // Pass row and col direction
                          numDecimals={numDecimals}
                          disabled={false}
                          inputRef={(el) => (inputRefs.current[rowIndex][colIndex] = el)}
                        />
                      </Tooltip>
                    </td>
                  );
                } else {
                  const { extraContent, className } = shouldApplyFormatting(product.name, column.name, value);
                  const displayValue = formatNumber(parseFloat(value));
                  return (
                    <td key={colIndex} className={className}>
                      {extraContent}
                      {displayValue}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductMainTable;

// Function to update values from pasted data starting from a specific row
const updateValuesFromPaste = async (pastedValues, column, userName, product, startRow) => {
  const last_update = formatDate(new Date())
  const body = []
  const is_percent_column = column.name.includes('%')
  pastedValues.forEach((value, rowIndex) => {
    const entryIndex = startRow + rowIndex; // Adjust to start from the clicked row
    if (entryIndex < column.quotations.length) {
      const entry = column.quotations[entryIndex];
      const entry_id = entry?.id;

      const item = {
        product_id: product.id,
        product_name: product.name,
        column_name: column.name,
        entry_id: entry_id,
        new_value: is_percent_column ? value / 100 : value,
        last_update_user: userName,
        last_update: last_update,
        index: entryIndex
      }
      body.push(item)
    }
  });

  await updateInBulk(body)
}
