import React, { useEffect, useState } from 'react';
import ProductMainTable from './ProductMainTable';
import SummaryTable from './SummaryTable';
import { getProductClass } from '../../helpers/productHelper';
import { computeQuarterlyAverages, computeYearlyAverages } from '../../helpers/summaryHelper';

const dontComputeAverages = new Set(["cape", "panama", "suez", "lpg3m2m", "cape_minus_panama"])

const ProductTable = ({ 
  product, 
  updateValue, 
  handleKeyDown, 
  quarters, 
  years, 
  dates,
  selectedProductName,
  setSelectedProductName,
  selectedColumn,
  setSelectedColumn
}) => {
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const tsColumn = product.columns.find(column => column.name === 'T/S');
  const otherColumns = product.columns.filter(column => column.name !== 'T/S');
  const columns = tsColumn ? [otherColumns[0], tsColumn, ...otherColumns.slice(1)] : product.columns;

  const quarterlyAverages = computeQuarterlyAverages(product, quarters);
  const yearlyAverages = computeYearlyAverages(product, years);
  const hasAverages = !dontComputeAverages.has(product.name.toLowerCase());

  // Handle product selection
  const handleProductClick = () => {
    setSelectedProductName(product.name); // Set the clicked product as the selected product
  };

  return (
    <div className="product-container" onClick={handleProductClick}> {/* Select product on click */}
      <h2 className={getProductClass(product.name)}>{product.name.replaceAll("_", " ")}</h2>
      <ProductMainTable
        product={product}
        columns={columns}
        dates={dates}
        updateValue={updateValue}
        handleKeyDown={handleKeyDown}
        selectedProductName={selectedProductName} // Pass down the selected product
        selectedColumn={selectedColumn} // Pass the selected column index
        setSelectedColumn={setSelectedColumn} // Pass function to change selected column
      />
      <SummaryTable
        columns={columns}
        averages={quarterlyAverages}
        hasAverages={hasAverages}
      />
      <SummaryTable
        columns={columns}
        averages={yearlyAverages}
        hasAverages={hasAverages}
      />
    </div>
  );
};

export default ProductTable;
