import '../../styles/ExcelLikePage.css';
import ErrorPage from './ErrorPage';
import IndexColumn from '../IndexColumn';
import LoadingAnimation from '../LoadingAnimation';
import ProductInputs from '../ProductInputs'; // Import the new component
import ProductTable from '../Page/ProductTable';
import React, { useEffect, useState } from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { getDatesFromProducts } from '../../helpers/timeHelper';
import { useFetchData } from '../../hooks/useFetchData';
import { useWebSocket } from '../../hooks/useWebSocket';
import { fetchPNL } from '../../services/apiService';

function parsePNLData(data, maturities) {
  const yearValues = {};

  // Find the "TOTAL PNL" entry in data
  const totalPNLEntry = data.find(entry => entry.category === "TOTAL PNL");
  if (!totalPNLEntry) return []; // Return empty array if "TOTAL PNL" entry is not found

  const values = Object.values(totalPNLEntry.total[0].data); // Get the data values for "TOTAL PNL"
  const minLength = Math.min(values.length, maturities.length);

  for (let i = 0; i < minLength; i++) {
    const year = maturities[i].split("-")[0];
    const value = values[i];

    // Sum the values by year
    yearValues[year] = (yearValues[year] || 0) + value;
  }

  // Convert the yearValues object into an array of { year, value } objects
  return Object.keys(yearValues)
    .sort()
    .map(year => ({
      year,
      value: yearValues[year]
    }));
}

const YearlyValuesTable = ({ data }) => {
  return (
    <table className="yearly-values-table">
      <thead>
        <tr>
          <th>Year</th>
          <th>P&L</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.year}>
            <td>{item.year}</td>
            <td>{item.value.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const LPGPage = () => {
  let { products, setProducts, setMaturities, loading, error, brentPrices, updateValue, handleKeyDown, updateValueExtraInput } = useFetchData();
  const extra_inputs = products.filter(p => p["extra_input"]).map(p => {
    return {
      "productName": p.name,
      "parameters": p["extra_input"]
    }
  }) || null;
  const [pnl, setPNL] = useState(undefined)

  const [selectedProductName, setSelectedProductName] = useState(null); // Track selected product
  const [selectedColumn, setSelectedColumn] = useState(null); // Track selected column

  useEffect(() => {
    if (!products || products.length === 0) {
      return
    }
    const fetchData = async () => {
      const result = await fetchPNL()
      const data = result["pnl"]
      const yearlyPNL = parsePNLData(data, result["maturities"])
      setPNL(yearlyPNL)
    };
    fetchData()
  }, [products])

  useWebSocket(setProducts, setMaturities);
  useDocumentTitle("LPG");

  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <ErrorPage error={error} />
  }

  const { dates, quarters, uniqueQuarters, years, uniqueYears } = getDatesFromProducts(products);

  return (
    <>
      {pnl && <YearlyValuesTable data={pnl} />}

      <div className="excel-container">
        <IndexColumn dates={dates} brentPrices={brentPrices} quarters={uniqueQuarters} years={uniqueYears} pnl={pnl} />
        {products.map((product) => (
          <ProductTable
            key={product.id}
            dates={dates}
            product={product}
            updateValue={updateValue}
            handleKeyDown={handleKeyDown}
            quarters={quarters}
            years={years}
            selectedProductName={selectedProductName}
            setSelectedProductName={setSelectedProductName}
            selectedColumn={selectedColumn}
            setSelectedColumn={setSelectedColumn}
          />
        ))}
      </div>

      {extra_inputs && extra_inputs.map(extra_input => (
        <ProductInputs
          extra_inputs={extra_input}
          updateValue={updateValueExtraInput}
        />
      ))
      }
    </>
  );
}


export default LPGPage;