import React from 'react';
import TablePage from '../Page/TablePage'; // Adjust the import path as necessary
import { useFetchData } from '../../hooks/useFetchData';
import { fetchPNL } from '../../services/apiService';


const PNLPage = () => {
  let { products, brentPrices } = useFetchData();

  products = products.filter(p =>
    p.name !== "H-F_Netback"
  )

  if (!brentPrices || brentPrices.length === 0 || !products || products.length === 0) {
    return null
  }

  const fetchData = async () => {
    const result = await fetchPNL();
    return { "data": result["pnl"], "maturities": result["maturities"] }
  }

  return <TablePage fetchData={fetchData} pageName={"P&L"} />;
};

export default PNLPage;
