import React from 'react';
import '../../styles/DataTable.css';
import LoadingAnimation from '../LoadingAnimation';
import { formatNumber } from '../../helpers/randomDataGenerator';
import { calculateRowTotal } from '../../helpers/averageHelper';
import { getCellClassName } from '../../helpers/cssHelper';
import ErrorPage from '../Pages/ErrorPage';


const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const transformMaturitiesToMonthNames = (maturities) => {
  // Map each date string to the short format
  return maturities.map(dateString => {
    const [year, month] = dateString.split('-');
    const shortYear = year.slice(2);
    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${monthName}${shortYear}`;
  });
}

const DataTable = ({ data, loading, error, months }) => {
  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <ErrorPage error={error} />
  }

  return (
    <div className="data-container">
      <table className="data-table">
        <thead>
          <tr>
            <th>Product</th>
            {transformMaturitiesToMonthNames(months).map(month => (
              <th key={month}>{month}</th>
            ))}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {data.map(({ category, products, total }) => (
            <React.Fragment key={category}>
              {/* Render Products */}
              {products && products.map(({ name, values }) => {
                const rowTotal = calculateRowTotal(values); // Precompute the row total
                return (
                  <tr key={name}>
                    <td>{name}</td>
                    {months.map((month, idx) => (
                      <td key={month} className={getCellClassName(values[idx])}>
                        {formatNumber(values[idx])}
                      </td>
                    ))}
                    <td className={getCellClassName(rowTotal)}>
                      {formatNumber(rowTotal)}
                    </td>
                  </tr>
                );
              })}

              {/* Render Totals */}
              {total && total.map(({ name, data }) => {
                const totalRowTotal = calculateRowTotal(months.map(m => data[m])); // Precompute total row total
                return (
                  <tr key={name} className="total-row">
                    <td>{name}</td>
                    {months.map(month => (
                      <td key={month} className={getCellClassName(data[month])}>
                        {formatNumber(data[month])}
                      </td>
                    ))}
                    <td className={getCellClassName(totalRowTotal)}>
                      {formatNumber(totalRowTotal)}
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          ))}

        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
