import React, { useEffect, useState } from 'react';
import DataTable from './DataTable'; // Adjust the import path as necessary
import useDocumentTitle from '../../hooks/useDocumentTitle';

const TablePage = ({ fetchData, pageName }) => {
  const [data, setData] = useState([]);
  const [months, setMonths] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useDocumentTitle(pageName);
  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const {data, maturities} = await fetchData();
        setData(data);
        setMonths(maturities)
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDataAsync();
  }, [fetchData]);

  return <DataTable data={data} loading={loading} error={error} months={months} />;
};

export default TablePage;
