import { useEffect } from 'react';
import WebSocketInstance from '../services/WebSocketService';
import { useUser } from '../context/UserContext';

export const useWebSocket = (setProducts, setMaturities) => {
  const { userName } = useUser()

  useEffect(() => {
    if (!userName)
      return
    WebSocketInstance.connect(userName);

    WebSocketInstance.addCallback('updateData', (message) => {
      const updatedProducts = JSON.parse(message);
      console.log("Received message", updatedProducts)
      setProducts(updatedProducts["products"])
      setMaturities(updatedProducts["maturities"])
    });

    return () => {
      WebSocketInstance.removeCallback('updateData');
      WebSocketInstance.disconnect();
    };
  }, [setProducts, userName]);
};
