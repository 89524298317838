import React from 'react';
import TablePage from '../Page/TablePage'; // Adjust the import path as necessary
import { fetchPositions } from '../../services/apiService';

const PositionsPage = () => {
  const fetchData = async () => {
    const result = await fetchPositions()
    return { "data": result["positions"], "maturities": result["maturities"] }
  };

  return <TablePage fetchData={fetchData} pageName={"Positions"} />;
};

export default PositionsPage;
