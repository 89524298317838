import React from 'react';
import '../styles/loading.css';

const LoadingAnimation = () => (
  <div className="loading-animation">
    <div className="spinner"></div>
    <div className="loading-text">Loading...</div>
  </div>
);

export default LoadingAnimation;
