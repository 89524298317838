import React from 'react';
import { formatNumber } from '../../helpers/formatNumber';

const SummaryTable = ({ columns, averages, hasAverages }) => (
    <>
        <table className="excel-table">
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={index}>{column.name}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {averages.map((item, index) => (
                    <tr key={index}>
                        {item.values.map((col, colIndex) => (
                            <td key={colIndex}>{hasAverages ? formatNumber(col.value) : "N/A"}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </>
);

export default SummaryTable;
