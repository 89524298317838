export const computeAverage = entries => {
  const values = entries.map(entry => parseFloat(entry));
  const sum = values.reduce((a, b) => a + b, 0);
  return values.length ? sum / values.length : 0;
};

export const computeTSColumn = (swap, productName) => {
  const multiplier = productName === "MB_Propane" ? 100 : 1;
  return swap.map((item, index) => ({
    name: "T/S",
    value: index < swap.length - 1 ? multiplier * (swap[index].value - swap[index + 1].value) : ""
  }));
};

export const calculateRowTotal = (rowValues) => {
  return rowValues.reduce((sum, value) => {
    if (value === '' || value === '-') {
      return sum;
    }
    return sum + parseFloat(value);
  }, 0);
};
