// src/components/Pages/NotFoundPage.js
import React from 'react';
import styles from '../../styles/NotFoundPage.module.css'; // Use CSS module

const NotFoundPage = () => {
  return (
    <div className={styles.notFoundBody}>
      <div className={styles.notFoundContainer}>
        <h1 className={styles.notFoundTitle}>404</h1>
        <p className={styles.notFoundMessage}>
          The page you're looking for doesn't exist.
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
