export const getProductClass = (productName) => {
  switch (productName) {
    case 'NWE_Naphtha':
      return 'h2-product h2-product-nwe-naphtha';
    case 'RT_C':
      return 'h2-product h2-product-mb-propane';
    case 'Netback':
      return 'h2-product h2-product-nwe-naphtha';
    case 'H-F_Netback':
      return 'h2-product h2-product-nwe-naphtha';
    case 'East_Naphtha':
      return 'h2-product h2-product-nwe-naphtha';
    case 'NWE_Propane':
      return 'h2-product h2-product-nwe-propane';
    case 'H-C_Netback':
      return 'h2-product h2-product-nwe-propane';
    case 'Butane_Swaps':
      return 'h2-product h2-product-nwe-propane';
    case 'MB_Propane':
      return 'h2-product h2-product-mb-propane';
    case 'C3_N_TET':
      return 'h2-product h2-product-nwe-propane';
    case 'FEI_Propane':
      return 'h2-product h2-product-fei-propane';
    case 'CP_Propane':
      return 'h2-product h2-product-cp-propane';
    case 'Sing_0.5':
      return 'h2-product h2-product-nwe-naphtha';
    case 'Sing_380':
      return 'h2-product h2-product-mb-propane';
    case 'HC_eqv':
      return 'h2-product h2-product-cp-propane';
    case 'Panama':
      return 'h2-product h2-product-fei-propane';
    case 'Suez':
      return 'h2-product h2-product-cp-propane';
    case 'Cape':
      return 'h2-product h2-product-nwe-propane';
    case 'Cape_minus_Panama':
      return 'h2-product h2-product-nwe-naphtha';
    case 'LPG3M2M':
      return 'h2-product h2-product-mb-propane';
    default:
      return 'h2-product';
  }
};

const UPDATE_DURATION_IN_SECONDS = process.env.NODE_ENV === "development" ? 5 : 60
// 1 -> greater than
// 0 -> equal to
// -1 -> less than
export const getBackgroundColorClass = (lastUpdate, change_direction) => {
  const now = new Date();
  const updateTime = new Date(lastUpdate);
  const timeDiff = (now - updateTime) / 1000

  if (timeDiff < UPDATE_DURATION_IN_SECONDS) {
    return change_direction === 1 ? "green" : (change_direction === - 1 ? "red" : "dark")
  } else {
    return 'dark';
  }
};


export const filterProducts = (products) => {
  for (const p of products) {
    if (p["category"] === "propane") {
      return p
    }
  }
  return []
}