import { useState, useEffect } from 'react';
import { UPDATE_BRENT_INTERVAL } from '../config/productConfig';
import { updateExtraInput } from '../helpers/updateHelper';
import { isValidFloat } from '../helpers/dataHelper';
import { updateEntry } from '../helpers/updateHelper';
import { formatDate } from '../helpers/timeHelper';
import { useUser } from '../context/UserContext';
import { fetchProducts, getFuturesData } from '../services/apiService';

export const useFetchData = () => {
  const [maturities, setMaturities] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [brentPrices, setBrentPrices] = useState([]);
  const { userName } = useUser()

  useEffect(() => {

    async function updateValues() {
      const [brentPrices, response] = await Promise.all([
        fetchBrentValues(),
        fetchProducts()
      ]);

      setBrentPrices(() => {
        setProducts(response["products"]);
        setMaturities(response["maturities"]);
        return brentPrices
      })
    }

    const initializeState = async () => {
      setLoading(true)
      try {
        await updateValues()

      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false)
      }
    }

    const fetchBrentValues = async () => {
      const result = await getFuturesData()
      const brentPrices = result["brentFutures"]

      return brentPrices
    };

    initializeState()

    const interval = setInterval(async () => {
      try {
        await updateValues();
      } catch (error) {
        console.error("Error fetching data: ", error);
      }

    }, UPDATE_BRENT_INTERVAL);



    return () => clearInterval(interval);
  }, []);

  const updateValueExtraInput = (productName, name, new_input_value, userName, update_db = false) => {
    if (update_db) {
      const last_update = formatDate(new Date())
      updateExtraInput(
        productName,
        new_input_value,
        name,
        userName,
        last_update
      );
    }
  }

  const updateValue = (productName, entryName, newValue, update_db) => {
    newValue = newValue === "" ? 0 : parseFloat(newValue);
    if (!isValidFloat(newValue)) {
      return;
    }

    setError(null);

    const updatedProducts = [...products];
    const currentProduct = updatedProducts.find(p => p.name === productName)

    const [columnName, entryIdStr, indexStr] = entryName.split('-');
    const entryId = parseInt(entryIdStr)
    const index = parseInt(indexStr)

    const last_update = formatDate(new Date())

    if (update_db) {
      updateEntry(
        currentProduct.id,
        columnName,
        entryId,
        newValue,
        userName,
        last_update,
        index
      );
    }
  }

  const handleKeyDown = async (e, productName, columnName, currentValue) => {
    if (e.key === 'Enter') {
      const newQuote = e.target.value;
      if (parseFloat(newQuote) !== currentValue) {
        updateValue(productName, columnName, newQuote, true);
      }
    }
  };
  return { products, setProducts, setMaturities, maturities, loading, error, userName, setError, brentPrices, updateValue, handleKeyDown, updateValueExtraInput };
};
