import { backendWSPath } from "../config/config";

class WebSocketService {
  static instance = null;
  callbacks = {};

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  constructor() {
    this.socketRef = null;
    this.isConnected = false;
  }

  connect(user) {
    if (!user) return;
    this.socketRef = new WebSocket(`${backendWSPath}/${user}`);

    this.socketRef.onopen = () => {
      console.log('WebSocket open');
      this.isConnected = true;
    };

    this.socketRef.onmessage = (e) => {
      this.socketNewMessage(e.data);
    };

    this.socketRef.onerror = (e) => {
      console.error('WebSocket error', e);
      this.isConnected = false;
    };

    this.socketRef.onclose = () => {
      console.log('WebSocket closed, reconnecting...');
      this.isConnected = false;
      setTimeout(() => this.connect(user), 1000);  // Reconnect after 1 second
    };
  }

  disconnect() {
    if (this.socketRef) {
      this.socketRef.close();
      this.isConnected = false;
    }
  }

  socketNewMessage(data) {
    Object.keys(this.callbacks).forEach((callbackName) => {
      this.callbacks[callbackName](data);
    });
  }

  addCallback(type, callback) {
    this.callbacks[type] = callback;
  }

  removeCallback(type) {
    delete this.callbacks[type];
  }

  sendMessage(data) {
    if (this.isConnected && this.socketRef && this.socketRef.readyState === WebSocket.OPEN) {
      try {
        this.socketRef.send(JSON.stringify({ ...data }));
      } catch (err) {
        console.error('WebSocket send error', err);
      }
    } else {
      console.error('WebSocket is not connected.');
    }
  }
}

const WebSocketInstance = WebSocketService.getInstance();
export default WebSocketInstance;
