// src/components/Navbar.js
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../styles/Navbar.css';
import { useUser } from '../context/UserContext';
import { useMsal } from '@azure/msal-react';

const Navbar = () => {
  const { userName } = useUser();
  const { instance } = useMsal();
  const location = useLocation()

  // Function to handle sign out
  const handleSignOut = async (e) => {
    e.preventDefault()
    try {
      await instance.logoutRedirect()
    } catch (err) {
      console.error(err);
    };
  };

  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li className={`navbar-item ${location.pathname === '/lpg' ? 'active' : ''}`}>
          <NavLink to="/lpg">LPG</NavLink>
        </li>
        <li className={`navbar-item ${location.pathname === '/positions' ? 'active' : ''}`}>
          <NavLink to="/positions" >Positions</NavLink>
        </li>
        <li className={`navbar-item ${location.pathname === '/pnl' ? 'active' : ''}`}>
          <NavLink to="/pnl" >P&L</NavLink>
        </li>
      </ul>
      <div className="navbar-user">
        <span className="navbar-item">{userName}</span>
        <button className="navbar-item signout-button" onClick={handleSignOut}>
          Sign Out
        </button>
      </div>
    </nav>
  );
};

export default Navbar;