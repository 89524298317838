import React from 'react';
import styles from '../../styles/ErrorPage.module.css'; // Import the CSS Module

const ErrorPage = ({error}) => {
  console.log("Error", error)

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.header}>Something Went Wrong</h1>
        <p className={styles.subheader}>Please try refreshing the page. If the issue persists, reach out to Sergio Fuentes Navarro on Teams.</p>
        <p className={styles.message}>
          An unexpected error has occurred. We apologize for the inconvenience.
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
