export const computeQuartersFromTS = (tsColumn) => {
  if (tsColumn.length === 0) return tsColumn
  return tsColumn.quotations.map(entry => {
    const date = new Date(entry.maturity);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (month >= 1 && month <= 3) {
      return `1Q ${year.toString().slice(-2)}`;
    } else if (month >= 4 && month <= 6) {
      return `2Q ${year.toString().slice(-2)}`;
    } else if (month >= 7 && month <= 9) {
      return `3Q ${year.toString().slice(-2)}`;
    } else {
      return `4Q ${year.toString().slice(-2)}`;
    }
  });
}

export const computeYearsFromTs = (tsColumn) => {
  if (tsColumn.length === 0) return tsColumn
  return tsColumn.quotations.map(entry => {
    const date = new Date(entry.maturity);
    return date.getFullYear();
  });
}

export const formatDate = (date) => {
  if (!date) return date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const getDatesFromProducts = (products) => {
  // Find the first product with a T/S column that has quotations
  const productWithQuotations = products.find(product => 
    product.columns.some(col => col.name === 'T/S' && col.quotations && col.quotations.length > 0)
  );

  // Check if we found such a product
  const showDateColumn = !!productWithQuotations;
  const dates = showDateColumn ? productWithQuotations.columns.find(col => col.name === 'T/S').quotations.map(entry => entry.maturity) : [];
  const tsColumn = dates.length > 0 ? productWithQuotations.columns.find(p => p.name === "T/S") : [];
  const quarters = tsColumn ? computeQuartersFromTS(tsColumn) : {};
  const uniqueQuarters = quarters.filter((quarter, index) => quarters.indexOf(quarter) === index);
  const years = computeYearsFromTs(tsColumn);
  const uniqueYears = years.filter((year, index) => years.indexOf(year) === index);
  
  return { dates, quarters, uniqueQuarters, years, uniqueYears };
};
