import { computeAverage, computeTSColumn } from './averageHelper';

const columnsToComputeTSFrom = ["swap", "lpg1", "lpg2", "lpg3", "nwe swap", "nap e/w"]

export const computeQuarterlyAverages = (product, quarters) => {
  const quartersData = {};

  product.columns.forEach(column => {
    if (column.quotations) {
      column.quotations.forEach((entry, i) => {
        const quarterLabel = quarters[i];
        if (!quartersData[quarterLabel]) quartersData[quarterLabel] = {};
        if (!quartersData[quarterLabel][column.name]) quartersData[quarterLabel][column.name] = [];
        quartersData[quarterLabel][column.name].push(entry.value || entry);
      });
    } else {
      column.value.forEach((entry, i) => {
        const quarterLabel = quarters[i];
        if (!quartersData[quarterLabel]) quartersData[quarterLabel] = {};
        if (!quartersData[quarterLabel][column.name]) quartersData[quarterLabel][column.name] = [];
        quartersData[quarterLabel][column.name].push(entry.value || entry);
      });
    }
  });

  let result = [];
  const swapCol = [];

  Object.keys(quartersData).forEach(quarter => {
    let quarterData = { quarter: quarter, values: [] };

    product.columns.forEach(column => {
      if (column.name === "T/S") return;
      const value = quartersData[quarter][column.name].value || quartersData[quarter][column.name] || []

      let columnData = {
        name: column.name,
        value: computeAverage(value)
      };

      quarterData.values.push(columnData);
      if (columnsToComputeTSFrom.includes(column.name.toLowerCase())) {
        swapCol.push(columnData);
      }
    });
    result.push(quarterData);
  });

  const tsColumnAveraged = computeTSColumn(swapCol, product.name);
  if (tsColumnAveraged && tsColumnAveraged.length > 0) {
    result.forEach((quarterData, index) => {
      quarterData.values.splice(1, 0, tsColumnAveraged[index]);
    });
  }

  return result;
};

export const computeYearlyAverages = (product, years) => {
  const yearsData = {};

  product.columns.forEach(column => {
    if (column.quotations) {
      column.quotations.forEach((entry, i) => {
        const yearLabel = years[i];
        if (!yearsData[yearLabel]) {
          yearsData[yearLabel] = {};
        }
        if (!yearsData[yearLabel][column.name]) {
          yearsData[yearLabel][column.name] = [];
        }
        yearsData[yearLabel][column.name].push(entry.value || entry);
      });
    } else {
      column.value.forEach((entry, i) => {
        const yearLabel = years[i];
        if (!yearsData[yearLabel]) {
          yearsData[yearLabel] = {};
        }
        if (!yearsData[yearLabel][column.name]) {
          yearsData[yearLabel][column.name] = [];
        }
        yearsData[yearLabel][column.name].push(entry.value || entry);
      });
    }
  });

  let result = [];
  const swapCol = [];

  Object.keys(yearsData).forEach(year => {
    let yearData = { year: year, values: [] };

    product.columns.forEach(column => {
      if (column.name === "T/S") return;

      const value = yearsData[year][column.name].value || yearsData[year][column.name] || []
      let columnData = {
        name: column.name,
        value: computeAverage(value)
      };

      yearData.values.push(columnData);
      if (columnsToComputeTSFrom.includes(column.name.toLowerCase())) {
        swapCol.push(columnData);
      }
    });
    result.push(yearData);
  });

  const tsColumnAveraged = computeTSColumn(swapCol, product.name);
  if (tsColumnAveraged && tsColumnAveraged.length > 0) {
    result.forEach((yearData, index) => {
      yearData.values.splice(1, 0, tsColumnAveraged[index]);
    });
  }

  return result;
};
