import React, { useState, useEffect } from 'react';
import '../styles/ColumnInput.css';
import { formatNumber } from '../helpers/formatNumber';
import { useUser } from '../context/UserContext';

const ColumnInput = ({
  value, onChange, onKeyDown, onFocusNext, disabled, numDecimals = 3, inputRef,
  product_name, column_name,
  entry_id, index
}) => {
  const { accessLevel } = useUser();
  const is_percent_column = column_name.includes("%");
  const [inputValue, setInputValue] = useState(value.toString());

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(is_percent_column ? (value * 100).toFixed(numDecimals) : value.toString());
    }
  }, [value, is_percent_column, numDecimals]);

  const handleInputChange = (e) => {
    const rawValue = e.target.value.replace("%", "");
    setInputValue(rawValue);
    onChange({
      ...e,
      target: {
        ...e.target,
        value: is_percent_column ? parseFloat(rawValue) / 100 : parseFloat(rawValue)
      }
    });
  };

  const handleBlur = () => {
    // Format the number when the input loses focus
    formatAndSetInputValue();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      formatAndSetInputValue();
      onKeyDown(e, product_name, `${column_name}-${entry_id}-${index}`);
      if (onFocusNext) {
        onFocusNext(1, 0); // Move focus to the next row (down)
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (onFocusNext) {
        onFocusNext(1, 0); // Down arrow
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (onFocusNext) {
        onFocusNext(-1, 0); // Up arrow
      }
    } else if (e.key === 'ArrowRight') {
      e.preventDefault();
      if (onFocusNext) {
        onFocusNext(0, 1); // Right arrow
      }
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      if (onFocusNext) {
        onFocusNext(0, -1); // Left arrow
      }
    }
  };

  const formatAndSetInputValue = () => {
    const parsedValue = parseFloat(inputValue.replace("%", ""));
    if (!isNaN(parsedValue)) {
      const formattedValue = formatNumber(parsedValue, numDecimals);
      setInputValue(formattedValue);
    } else if (inputValue === '' || isNaN(parsedValue)) {
      setInputValue('');
    }
  };

  return (
    <div className="input-container">
      <input
        type="text"
        value={is_percent_column ? `${inputValue}%` : inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        disabled={disabled}
        className="column-input"
        readOnly={accessLevel === "reader"}
        ref={inputRef} // Attach the inputRef passed from parent
      />
    </div>
  );
};

export default ColumnInput;
